import { defineStore } from "pinia";

import { components, operations } from "@/autogen/openapi";
import api from "@/lib/api/base";
import { ok } from "@/lib/openapi";

import {
  ListResource,
  useCreateListUpdateDeleteResource,
  useCreateListUpdateResource,
  useListResource,
} from "./base";
import {
  compose,
  Lazy,
  updateResourceOnMount,
  updateResourceOnNewsletterChange,
} from "./internal-api-mirror";

const checkForHydratedPayload = <M, O, R extends ListResource<M, O, any>>(
  path: string
): ((resource: R) => R) => {
  return (resource: R) => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const hydratedPayload = HYDRATED_PAYLOAD[path];
    if (hydratedPayload) {
      resource.resource.value = hydratedPayload;
    }
    return resource;
  };
};

type CommentParameters = operations["list_comments"]["parameters"]["query"];

type ExternalFeedInput = components["schemas"]["ExternalFeedInput"];
type ExternalFeedUpdateInput =
  components["schemas"]["ExternalFeedUpdateInput"] & { id: string };

type UserInput = components["schemas"]["UserInput"];
type UserUpdateInput = components["schemas"]["UserUpdateInput"] & {
  id: string;
};

type WebhookInput = components["schemas"]["WebhookInput"];
type WebhookUpdateInput = WebhookInput & { id: string };

type AdvertisingUnitInput = components["schemas"]["AdvertisingUnitInput"];
type AdvertisingUnitUpdateInput =
  components["schemas"]["AdvertisingUnitUpdateInput"] & { id: string };

type TagInput = components["schemas"]["TagInput"];
type TagUpdateInput = components["schemas"]["TagUpdateInput"] & { id: string };

type SurveyResponseParameters =
  operations["retrieve_survey_responses"]["parameters"]["query"];

type SurveyInput = components["schemas"]["SurveyInput"];
type SurveyUpdateInput = components["schemas"]["SurveyUpdateInput"] & {
  id: string;
};

export const useStore = defineStore(
  "openapi-mirror",
  () => {
    return {
      "/comments": new Lazy(() =>
        compose(
          useListResource(
            async (params: CommentParameters, { signal }) => {
              const data = await ok(
                api.get("/comments", {
                  signal,
                  params: {
                    query: params,
                  },
                })
              );

              return data;
            },
            {
              expand: ["subscriber", "email"],
            }
          )
        ).then([updateResourceOnMount, updateResourceOnNewsletterChange])
      ),
      "/external_feeds": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            async (_params: {}, { signal }) => {
              const data = await ok(
                api.get("/external_feeds", {
                  signal,
                })
              );

              return data;
            },
            async (input: ExternalFeedInput) => {
              const data = await ok(
                api.post("/external_feeds", {
                  body: input,
                })
              );

              return data;
            },
            async (input: ExternalFeedUpdateInput, { signal }) => {
              const { id, ...body } = input;

              const data = await ok(
                api.patch("/external_feeds/{id}", {
                  signal,
                  params: {
                    path: { id },
                  },
                  body: body,
                })
              );

              return data;
            },
            async ({ id }) => {
              await ok(
                api.delete("/external_feeds/{id}", {
                  params: {
                    path: { id },
                  },
                })
              );
            }
          )
        ).then([updateResourceOnNewsletterChange, updateResourceOnMount])
      ),
      "/users": new Lazy(() =>
        compose(
          useCreateListUpdateResource(
            async (_params: {}, { signal }) => {
              const data = await ok(api.get("/users", { signal }));

              return data;
            },
            async (input: UserInput) => {
              const data = await ok(
                api.post("/users", {
                  body: input,
                })
              );

              return data;
            },
            async (input: UserUpdateInput, { signal }) => {
              const { id, ...body } = input;

              const data = await ok(
                api.patch("/users/{id}", {
                  signal,
                  params: {
                    path: { id },
                  },
                  body: body,
                })
              );

              return data;
            }
          )
        ).then([updateResourceOnNewsletterChange, updateResourceOnMount])
      ),
      "/webhooks": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            async (_params: {}, { signal }) => {
              const data = await ok(
                api.get("/webhooks", {
                  signal,
                })
              );

              return data;
            },
            async (input: WebhookInput) => {
              const data = await ok(
                api.post("/webhooks", {
                  body: input,
                })
              );

              return data;
            },
            async (input: WebhookUpdateInput, { signal }) => {
              const { id, ...body } = input;

              const data = await ok(
                api.patch("/webhooks/{id}", {
                  signal,
                  params: {
                    path: { id },
                  },
                  body: body,
                })
              );

              return data;
            },
            async ({ id }) => {
              await ok(
                api.delete("/webhooks/{id}", {
                  params: {
                    path: { id },
                  },
                })
              );
            }
          )
        ).then([
          updateResourceOnNewsletterChange,
          updateResourceOnMount,
          checkForHydratedPayload("/webhooks"),
        ])
      ),
      "/coupons": new Lazy(() =>
        compose(
          useListResource(async (_params: {}, { signal }) => {
            const data = await ok(api.get("/coupons", { signal }));

            return {
              ...data,
              results: data.results.map((coupon) => ({
                ...coupon,
                id: coupon.coupon_id,
              })),
            };
          })
        ).then([updateResourceOnNewsletterChange])
      ),
      "/advertising_units": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            async (_params: {}, { signal }) => {
              const data = await ok(
                api.get("/advertising_units", {
                  signal,
                })
              );

              return data;
            },
            async (input: AdvertisingUnitInput) => {
              const data = await ok(
                api.post("/advertising_units", {
                  body: input,
                })
              );

              return data;
            },
            async (input: AdvertisingUnitUpdateInput, { signal }) => {
              const { id, ...body } = input;

              const data = await ok(
                api.patch("/advertising_units/{id}", {
                  signal,
                  params: {
                    path: { id },
                  },
                  body: body,
                })
              );

              return data;
            },
            async ({ id }) => {
              await ok(
                api.delete("/advertising_units/{id}", {
                  params: {
                    path: { id },
                  },
                })
              );
            }
          )
        ).then([updateResourceOnNewsletterChange, updateResourceOnMount])
      ),
      "/tags": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            async (_params: {}, { signal }) => {
              const data = await ok(
                api.get("/tags", {
                  signal,
                  params: {
                    query: {
                      page_size: 1000,
                    },
                  },
                })
              );

              return data;
            },
            async (input: TagInput) => {
              const data = await ok(
                api.post("/tags", {
                  body: input,
                })
              );

              return data;
            },
            async (input: TagUpdateInput, { signal }) => {
              const { id, ...body } = input;

              const data = await ok(
                api.patch("/tags/{id}", {
                  signal,
                  params: {
                    path: { id },
                  },
                  body: body,
                })
              );

              return data;
            },
            async ({ id }) => {
              await ok(
                api.delete("/tags/{id}", {
                  params: {
                    path: { id },
                  },
                })
              );
            }
          )
        ).then([
          updateResourceOnNewsletterChange,
          updateResourceOnMount,
          checkForHydratedPayload("/tags"),
        ])
      ),
      "/survey_responses": new Lazy(() =>
        compose(
          useListResource(
            async (params: SurveyResponseParameters, { signal }) => {
              const data = await ok(
                api.get("/survey_responses", {
                  signal,
                  params: {
                    query: params,
                  },
                })
              );

              return data;
            },
            {
              expand: ["subscriber", "email", "automation"],
            }
          )
        ).then([updateResourceOnNewsletterChange])
      ),
      "/surveys": new Lazy(() =>
        compose(
          useCreateListUpdateDeleteResource(
            async (_params: {}, { signal }) => {
              const data = await ok(
                api.get("/surveys", {
                  signal,
                })
              );

              return data;
            },
            async (input: SurveyInput) => {
              const data = await ok(
                api.post("/surveys", {
                  body: input,
                })
              );

              return data;
            },
            async (input: SurveyUpdateInput, { signal }) => {
              const { id, ...body } = input;

              const data = await ok(
                api.patch("/surveys/{id}", {
                  signal,
                  params: {
                    path: { id },
                  },
                  body: body,
                })
              );

              return data;
            },
            async ({ id }) => {
              await ok(
                api.delete("/surveys/{id}", {
                  params: {
                    path: { id },
                  },
                })
              );
            }
          )
        ).then([
          updateResourceOnNewsletterChange,
          updateResourceOnMount,
          checkForHydratedPayload("/surveys"),
        ])
      ),
    };
  },
  {
    broadcast: {},
  }
);
